import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"

import InfoWindow from "../components/infoWindow"

//import InfoSection from ""

const more = [
  <div>
    <ul>
      <li> Car park sweeping </li>
      <li> Pressure washing </li>
      <li> Street sweeping </li>
      <li> Striping </li>
      <li> Graffiti removal</li>
      <li> and more... </li>
    </ul>
    <p>Contact us to learn more.</p>
  </div>,
  <div>
    <ul>
      <li> A short professional analysis </li>
      <li> All relevant photographs </li>
      <li> Date of next needed services </li>
      <li> Cost of next needed services </li>
    </ul>
    Contact us for more information.
    <p></p>
  </div>,
  <div>
    <ul>
      <li> Deoderisers </li>
      <li> Periodic Inspections </li>
      <li> Service Report </li>
      <li> 24/7 Call-outs </li>
      <li> and more... </li>
    </ul>
    Contact us to customise the service or recieve a demo service report.
    <p></p>
  </div>,
]

const RegularService = (
  <div>
    <h2> FREE Inspection Reporting </h2>
    <p>
      <p>
        <h4>Regular inspections</h4> Keep ahead of complaints and costly
        long-term damage by opting into our FREE regular reporting programme.
        Our team periodically inspects your parking lot and parking facility and
        quotes cleaning and maintenance services as they are needed. If no
        services are needed a free follow-up inspection is scheduled.
      </p>
      <p>
        <h4>Personal reports</h4> All professional reports on you parking lot
        are sent conveniently to your email for your records.
      </p>
      <h4>Discount.</h4> Discounts apply to members on our regular inspection
      programme. <br />
      <a href="tel:0413231255">Call us for details</a>
    </p>
  </div>
)

const InspectionReporting = (
  <div>
    <h2> Car Park Cleaning. </h2>
    <i></i>
    <div>
      <p>
        <h4>Regular</h4> Care about the health of your car park? Want to give
        your customers, staff or residents a high quality car park experience?
        Our highly trained technicians are ready to clean your car parking lot
        or facility on a regular basis.
      </p>
      <p>
        <h4>Once-off</h4> Simply need a single intense deep clean? Our highly
        trained technicians are ready.
      </p>
      <h4>Discount</h4> Discounts apply to regular orders. <br />
      <a href="tel:0413231255">Call us for details</a>
      <br />
      <br />
      <p class="text-align-center">
        <h4>
          24/7 emergency service on <a href="tel:0413231255">0413 231 255.</a>
        </h4>
        Our trained technicians will reach you within 3 hours
      </p>
    </div>
  </div>
)

const ExtraMile = (
  <div>
    <h2>We go the extra mile</h2>
    <p>
      <h4>Innovative: </h4>
      Our experts are always looking to improve our service quality. Call our
      team to learn how we can provide an exceptional service to you.
      <br />
      <h4> Competitive pricing: </h4> Our competitors are often over-priced.
      Call us to negotiate.
      <br />
      <h4> Streamlined: </h4> Opt into our FREE inspection programme and never
      worry about your car park facility or lot again. We do the hard work so
      you don't have to.
    </p>
  </div>
)

const ChuteUnblocking = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "carpark-clean-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "carpark-clean-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "carpark-clean-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "carpark-clean-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout style={{ height: "80vh" }}>
      <SEO
        description="Our insured experts offer professional parking lot and facility cleaning across south east Queensland. Call us for more."
        lang="en"
        meta=""
        title="Car Park Cleaning | ChuteOff | Brisbane, Gold Coast, Sunshine Coast "
      />
      <section id="top">
        <InfoWindow
          id="chute-cleaning"
          title={<>Car Park Cleaning Services</>}
          subtitle="Your open parking lots, multi-level car oark structures, or underground parking garages in Brisbane, Gold Coast, and the Sunshine Coast!"
          panes={[InspectionReporting, RegularService, ExtraMile]}
          buttons={["CONTACT ON-CALL STAFF", "BOOK SERVICE", "CUSTOM JOB"]}
          button_subtitles={[
            "Not sure what you need? Talk to an expert",
            "After every service you receive a FREE personalised service report.",
            "Request a custom job tailored to your needs",
          ]}
          images={[
            data.image1.childImageSharp.fluid,
            data.image2.childImageSharp.fluid,
            data.image3.childImageSharp.fluid,
          ]}
          more={more}
          expansion_title={[
            "What car park cleaning services do we offer?",
            "What will I receive as a regular client?",
            "Does ChuteOff offer me any extras?",
          ]}
        />
      </section>

      <section className="container">
        <div className="row pd-30">
          <div className="col-lg-6">
            <h2>The Benefits of a Clean Car Park</h2>
            <p>Parking surfaces last longer when cleaned regularly:</p>
            <ul
              class="list-unstyled"
              style={{
                listStyleType: "disc",
                paddingLeft: "2em",
                paddingBottom: "1.8em",
              }}
            >
              <li>Removes abrasives that wear down driving surfaces</li>
              <li>Increases lifespan of driving surfaces</li>
              <li>Reduces overall maintenance costs </li>
              <li>Removes hazards that increase the risk of slips and falls</li>
              <li>
                Reduces environmental impact of petroleum, oil, and heavy metal
                pollutants
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <img
              src={data.image4.childImageSharp.fluid}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>
      <Process />
    </Layout>
  )
}

export default ChuteUnblocking
